<template>
  <auth-layout>
    <div class="cell auto text-center">
      <p>{{ $t('forms.pick_a_new_password') }}</p>
    </div>
    <div class="spacer--20"></div>
    <form @submit.prevent="formSubmit">
      <card-item :margin="true">
        <ab-input-field
          v-if="!hideCode"
          :label="$t('forms.password_reset_code')"
          v-model="resetData.reset_password_code"
          type="text"
          :error="$v.resetData.reset_password_code.$error"
          :errorMessage="$t('forms.place_password_reset_code')"
        ></ab-input-field>
        <ab-input-field
          v-if="!hideEmail"
          :label="$t('forms.email')"
          v-model="resetData.email"
          type="email"
          :error="$v.resetData.email.$error"
          :errorMessage="$t('forms.valid_email')"
        ></ab-input-field>
        <ab-input-field
          :label="$t('forms.password')"
          v-model="resetData.password"
          type="password"
          :error="$v.resetData.password.$error"
          :errorMessage="$t('forms.password_length')"
        ></ab-input-field>
        <ab-input-field
          :label="$t('forms.confirm_password')"
          v-model="resetData.password_confirmation"
          type="password"
          :error="$v.resetData.password_confirmation.$error"
          :errorMessage="$t('forms.password_does_not_match')"
        ></ab-input-field>
        <div class="login__error" v-if="submitStatus">
          <p>{{ submitStatus }}</p>
        </div>
      </card-item>
      <div class="spacer--20"></div>
      <div class="grid-x align-middle">
        <div class="cell auto">
          <router-link to="/login">{{
              $t('forms.return_to_login')
            }}
          </router-link>
        </div>
        <div class="cell auto text-right">
          <button type="submit" @click="formSubmit" class="button--primary">
            {{ $t('app.confirm') }}
          </button>
        </div>
      </div>
    </form>
  </auth-layout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout'
import backend from '@/backend'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'PasswordVerification',
  data () {
    return {
      hideCode: false,
      hideEmail: false,
      errors: false,
      resetData: {
        email: '',
        reset_password_code: '',
        password: '',
        password_confirmation: ''
      },
      submitStatus: null
    }
  },
  created () {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('code')) {
      this.resetData.reset_password_code = urlParams.get('code')
      this.hideCode = true
    }
    if (urlParams.has('email')) {
      this.resetData.email = urlParams.get('email')
      this.hideEmail = true
    }
  },
  validations: {
    resetData: {
      email: {
        required,
        email
      },
      reset_password_code: {
        required,
        minLength: minLength(1)
      },
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  methods: {
    formSubmit (e) {
      e.preventDefault()
      this.submitStatus = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        setTimeout(() => {
          backend
            .post(
              `${process.env.VUE_APP_URL}/users/reset_password`,
              this.resetData
            )
            .then(() => {
              this.$router.push('/login')
            })
            .catch(response => {
              this.submitStatus = this.$t('forms.data_is_incorrect')
            })
        }, 500)
      }
    }
  },
  components: {
    'auth-layout': AuthLayout
  }
}
</script>
